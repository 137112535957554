import { type UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LegacyTwoButtonDialogActions } from "@src/appV2/lib/Dialogs/LegacyDialogActions";
import { WorkerQuizResultStatus } from "@src/appV2/OpenShifts/ShiftAction/types";

interface WorkplaceQuizRequirementDialogProps {
  workplaceName: string;
  modalState: UseModalState;
  onContinue: () => void;
  workerQuizResultStatus?: WorkerQuizResultStatus;
}

export function WorkplaceQuizRequirementDialog(
  props: Readonly<WorkplaceQuizRequirementDialogProps>
) {
  const {
    modalState,
    workplaceName,
    onContinue,
    workerQuizResultStatus = WorkerQuizResultStatus.NEVER_TOOK_A_QUIZ,
  } = props;

  function getQuizRequirementDialogTitle(): string {
    if (workerQuizResultStatus === WorkerQuizResultStatus.TOOK_LATEST_QUIZ_A_MONTH_AGO) {
      return "Refresh your understanding of this workplace's rules";
    }

    if (workerQuizResultStatus === WorkerQuizResultStatus.TOOK_A_QUIZ_IN_PAST) {
      return "This workplace's rules have changed";
    }

    return "This workplace requires you to pass a short quiz";
  }

  return (
    <Dialog
      open={modalState.modalIsOpen}
      onClose={() => {
        modalState.closeModal();
      }}
    >
      <DialogTitle>{getQuizRequirementDialogTitle()}</DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogContentText>
          You&apos;ll need to demonstrate that you know the rules and expectations of{" "}
          {workplaceName} before you can book the shift.
        </DialogContentText>
        <br />
        <DialogContentText>Continue to see this facility&apos;s expectations.</DialogContentText>
      </DialogContent>
      <LegacyTwoButtonDialogActions
        leftAction={
          <Button
            onClick={() => {
              modalState.closeModal();
            }}
          >
            Cancel
          </Button>
        }
        rightAction={
          <Button
            onClick={() => {
              modalState.closeModal();
              onContinue();
            }}
          >
            Continue
          </Button>
        }
      />
    </Dialog>
  );
}
